<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="12" lg="12" cols="12">
          <b-card-actions
              ref="first-card"
              id="kasa-header"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-1 unPrint">
              <b-col xl="6" lg="6" md="6" xs="12" class="justify-content-start d-flex">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="secondary"
                    class="mr-50"
                    @click="$router.go(-1)"
                    size="sm"
                >
                  <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Geri Dön</span>
                </b-button>
<!--                <b-button-->
<!--                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                    variant="primary"-->
<!--                    class="mr-50"-->
<!--                    :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"-->
<!--                    size="sm"-->
<!--                >-->
<!--                  <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-50"/>-->
<!--                  <span class="align-middle">Excel'e Aktar</span>-->
<!--                </b-button>-->
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    @click="print"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                    size="sm"
                    class="mr-50"
                >
                  <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/>
                  <span class="align-middle">Yazdır</span>
                </b-button>
              </b-col>
              <b-col xl="6" lg="6" md="6" xs="12" class="justify-content-end d-flex">
                <b-input-group class="float-right justify-content-end">
                  <b-input-group-prepend>
                    <flat-pickr v-model="baslangic" class="form-control"/>
                  </b-input-group-prepend>
                  <b-button
                      variant="outline-secondary"
                      disabled
                      class="date-border-color"
                  >
                    <feather-icon icon="CalendarIcon"/>
                  </b-button>
                  <b-input-group-append>
                    <flat-pickr v-model="bitis" class="form-control"/>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card-actions>
          <b-card-actions
              ref="second-card"
              id="kasa-header"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Banka Havaleleri</h4>
                </b-col>
              </div>
            </b-row>
            <hr/>
            <div class="d-flex justify-content-between flex-wrap mt-5">
              <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                    class="unPrint"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                      size="sm"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search"/>
              </b-input-group>
            </div>
            <hr/>
            <!--            :items="listGet"-->
            <b-table
                striped
                hover
                responsive
                class="position-relative kasa-table mt-1"
                :per-page="perPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >
              <template #cell(stokID)="data">
                <b-badge variant="primary" id="firma-unvan-badge" class="align-top">
                  #{{ data.item.stokID }}
                </b-badge>
                <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
              </template>
              <template #cell(stokAdi)="data">
                <strong class="ml-50">
                  {{ data.item.stokAdi }}
                </strong>
              </template>
              <template #cell(grupAdi)="data">
                <b-badge
                    id="bakiye-badge"
                    variant="light-primary"
                    size="sm"
                >
                  {{ data.item.grupAdi }}
                </b-badge>
              </template>
              <template #cell(barkod)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      variant="primary"
                      v-if="data.item.barkod"
                  >
                    {{ data.item.barkod }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(alisFiyat)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      variant="primary"
                      v-if="data.item.alisFiyat"
                  >
                    {{ data.item.alisFiyat }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(adet)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      :variant="data.item.adet < 10 ? 'danger' : data.item.adet < 20 ? 'warning':'success'  "
                      v-if="data.item.adet"
                  >
                    {{ data.item.adet }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(kdv)="data">
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="updateStepOne(data.item)"
                    v-b-modal.edit
                    v-b-tooltip="'Düzenle'"
                >
                  <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="itemDelete(data.item.stokID)"
                    v-if="$store.getters.getRole == 1"
                    v-b-tooltip="'Sil'"
                >
                  <font-awesome-icon icon="fa-solid fa-trash-can"/>
                </b-button>
              </template>
            </b-table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="6">
                <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
              </b-col>
              <b-col cols="6">
                <b-pagination
                    v-model="currentPage"
                    @change="PageChange($event)"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 float-right"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BFormTextarea,
  BCard,
  BCardText,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BMedia,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    BFormTextarea,
    ModelListSelect,
    VueApexCharts,
    flatPickr,
    BCard,
    BCardText,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BMedia,
    BContainer,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      devirKayitBtn: false,
      devir: {
        not: '',
        tutar: '',
        tur: {
          key: 1,
          text: 'Kasa Girişi',
        },
        options: [
          {
            key: 1,
            text: 'Kasa Girişi',
          },
          {
            key: 2,
            text: 'Kasa Çıkışı',
          },
        ],
      },
      circleSeries: [
        {
          name: 'Visit source',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 335,
              name: 'Point One',
            },
            {
              value: 310,
              name: 'Point Two',
            },
            {
              value: 234,
              name: 'Point Three',
            },
            {
              value: 435,
              name: 'Point Four',
            },
          ],
        },
      ],
      baslangic: '',
      bitis: '',
      series: [
        {
          name: 'Gider',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 22, 70],
        },
        {
          name: 'Gelir',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 100, 65, 14],
        },
        {
          name: 'Vergi',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 20, 100, 90],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        colors: ['#E96479', '#408E91', '#FFBF9B'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' ₺'
            },
          },
        },
      },
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 0,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'stokID',
          label: 'STOK ID',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'stokAdi',
          label: 'STOK ADI',
          sortable: true,
          thStyle: {
            width: '33%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '33%' },
        },
        {
          key: 'grupAdi',
          label: 'STOK GRUBU',
          sortable: true,
          thStyle: {
            width: '30%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'barkod',
          label: 'BARKOD',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'alisFiyat',
          label: 'ALIŞ / SATIŞ',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'adet',
          label: 'ADET',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'kdv',
          label: '',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '9%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '9%' },
        },
      ],
    }
  },
  computed: {
    havaleListesi() {
      return store.getters.HAVALE_GET
    },
  },
  watch: {
    baslangic(val) {
    },
  },
  methods: {
    devirGirisi() {
      this.devirKayitBtn = true
      setTimeout(() => {
        this.devirKayitBtn = false
      }, 1000)
      this.loading(true)
      store.dispatch('kasaGirisi', {
        girisTuru: this.devir.tur.key,
        not: this.devir.not,
        tutar: this.devir.tutar,
      })
          .then(res => {
            // this.$bvModal.hide('kasa-devir')
            this.loading(false)
          })
          .catch(err => {
            this.loading(false)
          })
    },
    devirReset() {
      this.$bvModal.hide('kasa-devir')
      const t = this
      t.devir.tur = {
        key: 1,
        text: 'Kasa Girişi',
      }
      t.devir.not = ''
      t.devir.tutar = ''
    },
    print() {
      window.print()
    },
    loading(islem) {
      if (islem) {
        this.$refs['first-card'].showLoading = true
        this.$refs['second-card'].showLoading = true
        this.$refs['third-card'].showLoading = true
        this.$refs['fourth-card'].showLoading = true
      } else {
        this.$refs['first-card'].showLoading = false
        this.$refs['second-card'].showLoading = false
        this.$refs['third-card'].showLoading = false
        this.$refs['fourth-card'].showLoading = false
      }
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    // store.dispatch('bankaHavale')
  },
  beforeDestroy() {
    store.commit('BANKA_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#kasa-header .card-header {
  display: none !important;
}

.kasa-table .table thead th, table tbody td {
  padding: 0.2rem !important;
}
</style>
<style scoped>
.pull-up {
  transition: 150ms;
}

.search-bar {
  width: 15% !important;
}

.date-border-color {
  border-color: #bdb5d7 !important
}

.finans {
  white-space: nowrap !important;
}

.search-input {
  border-color: #c3baba !important;
}

.input-group {
  box-shadow: none !important;
}
</style>
